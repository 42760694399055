import styled from 'styled-components'
import { clamp, setVh } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { Heading2 } from '../../styles/vars/textStyles.style'
import { Button } from '../Button/index.style'
import { ContainerMain } from '../Container/index.style'
import { GridMain } from '../Grid/index.style'
import { GridItemMain } from '../GridItem/index.style'

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  ${setVh('height', 100)}
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ${easings.modal.default} 0s;
  z-index: 20;
  opacity: ${props => (props.modalOpen ? 1 : 0)};
  pointer-events: ${props => (props.modalOpen ? undefined : 'none')};

  ${mq.tabletL} {
    background: transparent;
  }

  ${ContainerMain} {
    opacity: ${props => (props.modalOpen ? 1 : 0)};
  }

  ${ContainerMain},
  ${GridMain},
  ${GridItemMain} {
    height: 100%;
  }
`

export const ContactInner = styled.div`
  background: ${colors.light};
  border-radius: 1rem;
  ${setVh('height', 90, true)}
  overflow: scroll;
  padding: 0rem 3.4rem 3.4rem 3.9rem;
  position: relative;
  ${setVh('top', 50, true)}
  transform: translateY(-50%);
  transition: opacity 1s ${easings.modal.default};
  opacity: ${props => (props.modalOpen ? 1 : 0)};
  pointer-events: ${props => (props.modalOpen ? undefined : 'none')};

  ${mq.tabletL} {
    height: auto;
    padding: 0rem calc((100% / 16) * 1) 3.9rem calc((100% / 16) * 1);
    position: relative;
  }

  ${Heading2} {
    padding-top: 4rem;
    position: relative;

    ${mq.tabletL} {
      padding-top: 11rem;
    }
  }

  ${Button} {
    ${mq.tabletLMax} {
      margin-top: 3rem;
    }
  }
`

export const CloseButton = styled.div`
  background: ${colors.light};
  margin-bottom: 1.4rem;
  padding-bottom: 0rem;
  padding-top: 3.9rem;
  position: sticky;
  top: 0rem;
  z-index: 2;

  ${mq.tabletL} {
    position: absolute;
    right: 5rem;
    text-align: right;
  }
`
export const Close = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  font-family: ${font.secondary.family};
  font-size: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;

  ${mq.tabletL} {
    margin-right: 0;
  }
`

export const Content = styled.div`
  overflow: hidden;
  transition: max-height 1s ease;
  ${({ feedbackMessage }) => {
    return feedbackMessage ? `max-height: 0rem;` : `max-height: 150vh;`
  }}
`

export const ContactForm = styled.form`
  width: 100%;

  ${Button} {
    text-align: center;

    ${mq.tabletL} {
      text-align: right;
    }
  }
`

export const ContactInput = styled.input`
  background: transparent;
  border: 0.1rem solid ${colors.dark};
  border-radius: 1rem;
  font-family: ${font.secondary.family};
  display: block;
  min-height: 6rem;
  outline: none;
  padding: 1.4rem 2rem;
  width: 100%;

  &:nth-of-type(2n-1) {
    margin-bottom: 2rem;
  }

  ${mq.tabletL} {
    display: inline-block;
    margin-right: 0;
    width: calc(50% - 1rem);

    &:nth-of-type(2n-1) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  font-size: ${clamp('font-size', 16, 19)};
`

export const ContactTextArea = styled.textarea`
  background: transparent;
  border: 0.1rem solid ${colors.dark};
  border-radius: 1rem;
  font-family: ${font.secondary.family};
  display: block;
  margin-top: 2rem;
  outline: none;
  padding: 1.4rem 2rem;
  width: 100%;

  font-size: ${clamp('font-size', 16, 19)};
`
export const ContactLabel = styled.label`
  height: 0rem;
  left: -1rem;
  pointer-events: none;
  position: absolute;
  top: -1rem;
  visibility: hidden;
  width: 0rem;
`

export const FeedbackMessage = styled.div`
  overflow: hidden;
  ${({ feedbackMessage }) => {
    return feedbackMessage
      ? `max-height: 150vh;
  transition: max-height 1s ease 1.1s;`
      : `max-height: 0rem;
  transition: max-height .2s ease 0s;`
  }}
`
