import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  // useRef,
} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import { isDesktop } from 'react-device-detect'

// Components
import { StoreDispatch, StoreState } from '../../Store'
import Contact from '../Contact'
import TransitionMask from '../PageTransitionMask'
import Cursor from '../Cursor'
import Gridlines from '../Gridlines'
import Header from '../Header'
// import Footer from '../Footer'
import Scroll from '../Scroll'

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import ScrollSection from '../ScrollSection'
import Container from '../Container'

const Layout = ({ children, location }) => {
  const dispatch = useContext(StoreDispatch),
    state = useContext(StoreState)

  const [keyMode, setKeyMode] = useState(false)
  const [mousePos, setMousePos] = useState([-200, -200])
  const [vw, setVw] = useState(null)

  useEffect(() => {
    dispatch({
      type: 'UPDATE_PATHNAME',
      payload: window.location.pathname,
    })
    dispatch({ type: 'UPDATE_POP_STATE' })
  }, [dispatch, location])

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    setVw(window.innerWidth)
  }

  const setMouseMode = useCallback(() => {
    if (!keyMode) return
    setKeyMode(false)
    document.body.classList.remove('keyboard-in-use')
  }, [keyMode])

  const setKeyboardMode = useCallback(() => {
    if (keyMode) return
    setKeyMode(true)
    document.body.classList.add('keyboard-in-use')
  }, [keyMode])

  const handleMouseMove = e => {
    const ev = e.touches ? e.touches[0] : e
    setMousePos([ev.clientX, ev.clientY])
  }

  useEffect(() => {
    setViewportHeight()

    window.addEventListener('resize', setViewportHeight)
    document.body.addEventListener('mousedown', setMouseMode)
    document.body.addEventListener('keydown', setKeyboardMode)
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('touchstart', handleMouseMove)
    document.addEventListener('touchmove', handleMouseMove)

    return () => {
      window.removeEventListener('resize', setViewportHeight)
      document.body.removeEventListener('mousedown', setMouseMode)
      document.body.removeEventListener('keydown', setKeyboardMode)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('touchstart', handleMouseMove)
      document.removeEventListener('touchmove', handleMouseMove)
    }
  }, [dispatch, setVw, setMouseMode, setKeyboardMode])

  useEffect(() => {
    const scrollbar = document.querySelector('.c-scrollbar')

    if (scrollbar) {
      if (state.modalIsOpen !== 0 && !scrollbar.classList.contains('hidden')) {
        scrollbar.classList.add('hidden')
      } else {
        if (scrollbar.classList.contains('hidden')) {
          scrollbar.classList.remove('hidden')
        }
      }
    }
  }, [state.modalIsOpen])

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <Gridlines show={false} />

      <Scroll callbacks={location} desktop={vw > breakpoints.tabletL} />

      <div id="scroll-container" data-scroll-container>
        <Contact />
        <Container>
          <ScrollSection>
            <Header pathname={location.pathname} />
          </ScrollSection>

          {children}
        </Container>
      </div>

      <TransitionMask />
      <Cursor x={mousePos[0]} y={mousePos[1]} location={location} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
