import React, { useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '../PageTransitionLink'
import MenuToggle from '../MenuToggle'
import { StoreDispatch, StoreState } from '../../Store'
import {
  HeaderContainer,
  HeaderLogo,
  HeaderLogoDesk,
  HeaderMain,
  HeaderMenu,
  HeaderMenuItem,
  HeaderNav,
  MobileMenu,
  MobileMenuItem,
  MobileNav,
  MobileNavMain,
  MobileNavLogo,
  MobilePortal,
} from './index.style'
import Logo from '../svgs/Logo'
import LogoMobile from '../svgs/LogoMobile'
import Logomark from '../svgs/LogoDots'
import GetInTouch from '../GetInTouchButton'
import Portal from '../Portal'

const Header = ({ pathname }) => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)
  const { mobileNavIsOpen } = store

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulBlockMenu(filter: { title: { eq: "Header Menu" } }) {
            edges {
              node {
                menuItem {
                  title
                  link
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <HeaderContainer>
            <HeaderMain>
              <HeaderLogo>
                <PageTransitionLink underline={true} to="/">
                  <LogoMobile />
                </PageTransitionLink>
              </HeaderLogo>

              <HeaderLogoDesk>
                <PageTransitionLink underline={true} to="/">
                  <Logo />
                </PageTransitionLink>
              </HeaderLogoDesk>

              {/* <MenuToggle /> */}

              <HeaderNav>
                <HeaderMenu>
                  <HeaderMenuItem>
                    {data.allContentfulBlockMenu.edges[0].node.menuItem.map(
                      (item, key) => {
                        return key ===
                          data.allContentfulBlockMenu.edges[0].node.menuItem
                            .length -
                            1 && item.link?.startsWith('/') ? (
                          <PageTransitionLink
                            to={item.link}
                            underline={true}
                            hasborder={'true'}
                            key={key}
                            theme={0}
                          >
                            {item.title}
                          </PageTransitionLink>
                        ) : key ===
                          data.allContentfulBlockMenu.edges[0].node.menuItem
                            .length -
                            1 ? (
                          <a href={item.link} key={key} theme={0} underline={true}>
                            {item.title}
                          </a>
                        ) : item.link?.startsWith('/') ? (
                          <PageTransitionLink
                            to={item.link}
                            key={key}
                            underline={true}
                            theme={0}
                          >
                            {item.title}
                          </PageTransitionLink>
                        ) : (
                          <a href={item.link} key={key} underline={true}>
                            {item.title}
                          </a>
                        )
                      }
                    )}
                  </HeaderMenuItem>
                </HeaderMenu>
              </HeaderNav>
            </HeaderMain>
            <Portal>
              <MobilePortal>
                <MenuToggle />
                <MobileNav open={mobileNavIsOpen}>
                  <MobileNavMain>
                    <MobileMenu>
                      <MobileMenuItem>
                        {data.allContentfulBlockMenu.edges[0].node.menuItem.map(
                          (item, key) => {
                            return item.link?.startsWith('/') ? (
                              <PageTransitionLink
                                to={item.link}
                                key={key}
                                underline={true}
                                onClick={
                                  item.link === pathname
                                    ? () => {
                                        dispatch({ type: 'HIDE_MOBILE_NAV' })
                                        dispatch({ type: 'CIRCLE_SHRINK' })
                                        dispatch({ type: 'HIDE_MODAL' })
                                        document
                                          .querySelector('body')
                                          .classList.remove('disable-scroll')
                                      }
                                    : null
                                }
                              >
                                {item.title}
                              </PageTransitionLink>
                            ) : (
                              <a href={item.link} key={key} underline={true}>
                                {item.title}
                              </a>
                            )
                          }
                        )}
                        <GetInTouch></GetInTouch>
                      </MobileMenuItem>
                    </MobileMenu>
                  </MobileNavMain>
                  <MobileNavLogo>
                    <Logomark responsive />
                  </MobileNavLogo>
                </MobileNav>
              </MobilePortal>
            </Portal>
          </HeaderContainer>
        )
      }}
    />
  )
}

export default Header
