import styled from 'styled-components'

export const GridlinesMain = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  pointer-events: none;
  z-index: 1000;

  > [data-grid] {
    height: 100%;
  }
`

export const GridlinesColumn = styled.div`
  width: 100%;
  height: 100vh;
  border-width: 0px 1px 0 1px;
  border-style: solid;
  border-color: ${({ dark }) =>
    dark ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)'};
`
