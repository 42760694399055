import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'

export const CursorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 999;
`

export const CursorIcon = styled.div.attrs(
  ({ x, y, xVelocity, yVelocity }) => ({
    style: {
      transform: `translateX(${x}px) translateY(${y}px) rotateZ(${
        xVelocity / 5
      }deg) rotateZ(${yVelocity / 5}deg)`,
    },
  })
)`
  height: 2.2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.9rem;
  transition: opacity 0.5s ${easings.modal.default};
  z-index: 2;

  ${({ show }) => {
    return show === false
      ? `
        opacity: 1;
        transition-delay: .25s;
      `
      : `
        opacity: 0;transition-delay: 0s;
      `
  }}
`

export const DragCursorIcon = styled.div.attrs(
  ({ x, y, xVelocity, yVelocity }) => ({
    style: {
      transform: `translateX(calc(${x}px - 3.7rem)) translateY(calc(${y}px - 3.7rem)) rotateZ(0)`,
    },
  })
)`
  display: inline-block;
  height: 7.4rem;
  width: 7.4rem;
`
export const DragCursorInner = styled.div`
  background: ${colors.light};
  border: 0.1rem solid ${colors.dark};
  border-radius: 50%;
  transform-origin: center;
  transition: transform 0.25s ${easings.modal.default};
  height: 7.4rem;
  width: 7.4rem;

  ${({ show }) => {
    return show === true
      ? `
      transform: scale(1);

      ${DragCursorText} {
        opacity: 1;
        transition-delay: .05s;
      }
      `
      : `
      transform: scale(0);
      transition-delay: .05s;

        ${DragCursorText} {
        opacity: 0;transition-delay: 0s;
      }
      `
  }}
`
export const DragCursorText = styled.span`
  color: ${colors.dark};
  display: inline-block;
  font-family: ${font.secondary.family};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 0.25s ${easings.modal.default};
`
