import React from 'react'

// Components

import { Button } from './index.style'
import PageTransitionLink from '../PageTransitionLink'

// SVG
// import ButtonBorder from '../svgs/ButtonBorder/'

/**
 * Check if button has type=submit (only used on form)
 * Check if it has an event attached to it
 * If neither of the above, it should be a link
 * -Check if it starts with '/' and it will be an internal link
 * -Otherwise render as regular link
 */

const Btn = ({ text, theme, to, event, hasborder, type, narrow, ...props }) => {
  const colourArray = ['#cc9f68', '#c88885', '#be9cc1', '#92a8bc', '#92babc']

  if (type === 'submit') {
    return (
      <Button
        theme={colourArray[theme]}
        hasborder={hasborder}
        narrow={narrow}
        underline={true}
        {...props}
      >
        <button type={type}>
          <span>{text}</span>
        </button>
      </Button>
    )
  }

  if (type === 'open') {
    return (
      <Button theme={colourArray[theme]} underline={true} hasborder={hasborder} narrow={narrow}>
        <button onClick={event} {...props}>
          <span>{text}</span>
        </button>
      </Button>
    )
  }

  return event ? (
    <Button theme={colourArray[theme]} underline={true} hasborder={hasborder} narrow={narrow}>
      <a
        href={'/'}
        onClick={e => {
          e.preventDefault()
          event()
        }}
        {...props}
      >
        <span>{text}</span>
      </a>
    </Button>
  ) : to?.startsWith('/') ? (
    <Button theme={colourArray[theme]} underline={true} hasborder={hasborder}>
      <PageTransitionLink to={to} underline={true}>{text}</PageTransitionLink>
    </Button>
  ) : (
    <Button theme={colourArray[theme]} hasborder={hasborder}>
      <a href={to}>{text}</a>
    </Button>
  )
}

export default Btn
