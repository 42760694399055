import { useEffect } from 'react'
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from 'locomotive-scroll'
import { animation } from '../../styles/vars/animation.style'

const Scroll = ({ callbacks, desktop }) => {
  useEffect(() => {
    const locomotiveScroll = new LocomotiveScroll({
      el: document.getElementById('scroll-container'),
      smooth: true,
      smartphone: {
        smooth: false,
      },
      direction: 'vertical',
      getDirection: true,
      touchMultiplier: 2.5,
      firefoxMultiplier: 70,
      lerp: 0.1,
      repeat: false,
      class: 'is-inview',
      reloadOnContextChange: true,
    })

    window.scroll = locomotiveScroll
    window.scroll.stop()

    const timeout = setTimeout(() => {
      window.scroll.update()
      window.scroll.start()

      window.scroll.on('scroll', args => {
        document.documentElement.setAttribute('data-direction', args.direction)
      })
    }, animation.maskHideDuration * 1000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      if (window.scroll) {
        window.scroll.destroy()
      }
    }
  }, [callbacks.pathname, desktop])

  return null
}

export default Scroll
