import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
// import { clamp } from '../../styles/utils/conversion.style'
// import { breakpoints } from '../../styles/vars/breakpoints.style'

export const ContainerMain = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  transition: opacity 1s ease;

  ${({ modalIsOpen }) => {
    return modalIsOpen === 1
      ? `opacity: 0;`
      : modalIsOpen === 0.5
      ? `opacity: .5;`
      : `opacity: 1;`
  }}

  ${mq.tabletL} {
    padding-left: 0;
    padding-right: 0;
  }
`
