import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const SceneWrapper = styled.div`
  background: #FDF5ED;
  min-height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;

  ${mq.desk} {
    position: absolute;
  }
`

export const SceneCanvas = styled.canvas`
  display: none;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
`
