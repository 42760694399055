import styled from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { Button } from '../Button/index.style'

export const GetInTouchWrapper = styled.div`
  display: inline-flex;
  font-family: ${font.secondary.family};
  font-weight: 600;
  justify-content: flex-end;
  letter-spacing: 0.01em;

  div {
    margin-top: 0;
  }

  ${Button} {
    margin-top: 0;
  }

  a {
    /* border: none; */
  }
`
