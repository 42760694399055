import React from 'react'
import PropTypes from 'prop-types'
import { GridlinesColumn, GridlinesMain } from './index.style'
import Grid from '../Grid'
import { grid } from '../../styles/vars/grid.style'
import GridItem from '../GridItem'

const Gridlines = ({ show, dark }) => {
  const gridColumns = []

  for (let i = 0; i < grid.colCount; i++) {
    gridColumns.push(
      <GridItem tiny={1}>
        <GridlinesColumn dark={dark} />
      </GridItem>
    )
  }

  return (
    <>
      {show && (
        <GridlinesMain>
          <Grid data-grid>
            {React.Children.toArray(gridColumns.map(column => column))}
          </Grid>
        </GridlinesMain>
      )}
    </>
  )
}

Gridlines.propTypes = {
  show: PropTypes.bool,
  dark: PropTypes.bool,
}

Gridlines.defaultProps = {
  show: false,
  dark: true,
}

export default Gridlines
