import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'
// import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/zIndex.style'

export const TransitionMaskWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  z-index: ${zIndex.pageTransitionMask};
`

export const TransitionMaskBlock = styled.div`
  position: relative;
  width: 100%;
  ${setVh('height', 100)}
  z-index: 1;
  /* background: linear-gradient(
    180deg,
    #ffc783 0%,
    #fbaba7 23.44%,
    #eec3f2 50%,
    #b7d2ec 73.96%,
    #b7e9ec 100%
  ); ; */
`
