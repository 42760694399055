import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import { easings } from '../../styles/vars/easings.style'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { font } from '../../styles/vars/font.style'
import { MenuToggleWrapper } from '../MenuToggle/index.style'

export const HeaderContainer = styled.header`
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};

  ${mq.tabletL} {
    left: auto;
    right: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(((100% / 16) * 14) + 4.4rem);
  }
`

export const HeaderMain = styled.div`
  align-items: center;
  display: flex;
  font-family: ${font.secondary.family};
  justify-content: space-between;
  ${clamp('padding-top', 12, 24, breakpoints.mobile, breakpoints.tabletL)}
`

export const HeaderLogo = styled.div`
  position: relative;
  z-index: ${zIndex.mobileNavButtons};

  a {
    padding-left: 0;
    &:after {
      display: none;
    }
  }

  ${mq.tabletL} {
    display: none;
  }
`

export const HeaderLogoDesk = styled.div`
  display: none;
  position: relative;
  z-index: ${zIndex.mobileNavButtons};

  a {
    &:after {
      display: none;
    }
  }

  ${mq.tabletL} {
    display: block;
  }
`

export const HeaderNav = styled.nav`
  display: none;

  ${mq.tabletL} {
    display: block;
  }
`

export const HeaderMenu = styled.ul`
  display: none;

  ${mq.tabletL} {
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    text-align: right;
  }
`

export const HeaderMenuItem = styled.li`
  text-transform: uppercase;

  ${PageTransitionLinkMain} {
    display: inline-flex;

    ${mq.tabletL} {
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  a,
  button {
    font-size: 1.6rem;

    ${mq.deskL} {
      font-size: 1.8rem;
    }
  }
`

export const MobileNavLogo = styled.div`
  position: absolute;
  left: 4.2rem;
  bottom: 8.2rem;
  width: calc((100% / 16) * 1.5);

  a {
    &:after {
      display: none;
    }
  }
`

export const MobilePortal = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  ${mq.tabletL} {
    bottom: auto;
  }

  ${MenuToggleWrapper} {
    font-family: ${font.secondary.family};
    left: 2.2rem;
    pointer-events: none;
    position: absolute;
    right: 2.2rem;
    top: 2.2rem;
    text-align: right;

    button {
      pointer-events: all;
    }
  }
`

export const MobileNav = styled.div`
  align-items: center;
  display: flex;
  /* ${setVh('height', 100)} */
  justify-content: center;
  /* max-height: 90vh; */
  left: 0;
  bottom: 0;
  pointer-events: ${props => (props.open ? undefined : 'none')};
  position: absolute;
  text-align: center;
  top: 0;
  opacity: ${props => (props.open ? ' 1' : '0')};
  ${props =>
    props.open
      ? `transition: opacity 1s ${easings.modal.default};
      transition-delay:  .5s;`
      : `transition: opacity .5s  ${easings.modal.default};`}
  width: 100%;
  z-index: ${zIndex.mobileNav};

  ${mq.tabletL} {
    display: none;
  }
`

export const MobileNavMain = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MobileMenu = styled.ul`
  height: 100%;
`

export const MobileMenuItem = styled.li`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-transform: uppercase;

  a {
    margin-bottom: 1rem;
  }

  a,
  button {
    font-size: 2.4rem;
  }
`
