export const font = {
  primary: {
    family: 'Nib',
    weight: {
      regular: 400,
    },
  },
  secondary: {
    family: 'Fellix',
    weight: {
      medium: 500,
      semibold: 600,
    },
  },
  fallback: {
    family: 'sans-serif',
  },
}
