import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Logo = ({
  width = 250,
  height = 20,
  fill = colors.dark,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250 20"
      aria-labelledby="logo"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id="logo">Hume</title>
      <path
        d="M4.53781 5.26609V2.0168H2.21288V5.26609H0V7.14284H2.21288V19.7479H4.53781V7.14284H8.48738V5.26609H4.53781Z"
        fill={fill}
      />
      <path
        d="M22.6884 12.549C22.6884 9.18763 21.4839 7.17083 18.3746 7.17083C15.4055 7.17083 13.3326 9.43973 13.3326 12.549V19.7478H10.9797V0.112015H13.3326V7.89912C14.313 6.19044 16.3578 5.15402 19.1309 5.15402C23.4447 5.15402 25.0693 7.98315 25.0693 12.549V19.7478H22.6884V12.549Z"
        fill={fill}
      />
      <path
        d="M41.0641 7.3949C39.6636 5.93832 37.7588 5.15401 35.658 5.15401C33.6132 5.15401 31.6804 5.8823 30.2798 7.22683C28.8233 8.59938 28.0109 10.5041 28.0109 12.577C28.0109 14.6498 28.8233 16.5545 30.2798 17.9271C31.7084 19.2716 33.6132 19.9999 35.658 19.9999C38.6552 19.9999 41.3442 18.3473 42.5487 15.7982L40.616 14.8739C39.7476 16.9187 37.8429 18.1792 35.658 18.1792C32.9689 18.1792 30.784 16.2744 30.3639 13.5854V13.5293H43.249C43.305 11.0924 42.5487 8.93551 41.0641 7.3949ZM30.3359 11.6526V11.5966C30.756 8.85148 32.9129 7.00274 35.658 7.00274C38.4031 7.00274 40.5599 8.85148 40.9801 11.5966V11.6526H30.3359Z"
        fill={fill}
      />
      <path d="M142.409 5.26611H140.14V19.7479H142.409V5.26611Z" fill={fill} />
      <path
        d="M141.289 0C140.505 0 139.861 0.644257 139.861 1.42857C139.861 2.24089 140.505 2.88515 141.289 2.88515C142.074 2.88515 142.718 2.24089 142.718 1.42857C142.718 0.644257 142.074 0 141.289 0Z"
        fill={fill}
      />
      <path
        d="M159.411 19.7478V12.549C159.411 10.14 159.019 8.34728 158.179 7.1428C157.282 5.82627 155.882 5.15401 153.893 5.15401C151.288 5.15401 149.523 6.05036 148.459 7.87109L148.375 8.03916L148.291 5.26605H146.134V19.7478H148.403V12.549C148.403 10.9523 148.823 9.60778 149.635 8.62739C150.476 7.61899 151.708 7.08678 153.193 7.08678C155.91 7.08678 157.198 8.82347 157.198 12.549V19.7478H159.411Z"
        fill={fill}
      />
      <path d="M165.265 5.26611H162.996V19.7479H165.265V5.26611Z" fill={fill} />
      <path
        d="M164.117 0C163.333 0 162.689 0.644257 162.689 1.42857C162.689 2.24089 163.333 2.88515 164.117 2.88515C164.902 2.88515 165.546 2.24089 165.546 1.42857C165.574 0.644257 164.93 0 164.117 0Z"
        fill={fill}
      />
      <path d="M181.596 5.26611H179.327V19.7479H181.596V5.26611Z" fill={fill} />
      <path
        d="M180.477 0C179.692 0 179.048 0.644257 179.048 1.42857C179.048 2.24089 179.692 2.88515 180.477 2.88515C181.261 2.88515 181.905 2.24089 181.905 1.42857C181.905 0.644257 181.261 0 180.477 0Z"
        fill={fill}
      />
      <path
        d="M197.534 5.26605L197.45 8.03916L197.366 7.87109C196.386 6.16241 194.201 5.15401 191.512 5.15401C189.607 5.15401 187.842 5.91031 186.554 7.25484C185.237 8.62739 184.509 10.5041 184.509 12.5209C184.509 14.5377 185.237 16.4145 186.554 17.787C187.87 19.1596 189.635 19.8879 191.512 19.8879C194.509 19.8879 196.47 18.9635 197.366 17.1708L197.45 17.0027L197.534 19.7198H199.691V5.26605H197.534ZM192.072 18.0111C189.075 18.0111 186.722 15.6022 186.722 12.549C186.722 9.49574 189.075 7.08678 192.072 7.08678C195.069 7.08678 197.45 9.49574 197.45 12.549C197.45 15.6022 195.069 18.0111 192.072 18.0111Z"
        fill={fill}
      />
      <path
        d="M211.4 5.26609H207.422V2.0168H205.153V5.26609H202.94V7.05881H205.153V19.7479L207.422 19.7199V7.05881H211.4V5.26609Z"
        fill={fill}
      />
      <path
        d="M215.041 0C214.256 0 213.612 0.644257 213.612 1.42857C213.612 2.24089 214.256 2.88515 215.041 2.88515C215.825 2.88515 216.469 2.24089 216.469 1.42857C216.469 0.644257 215.825 0 215.041 0Z"
        fill={fill}
      />
      <path d="M216.161 5.26611H213.893V19.7479H216.161V5.26611Z" fill={fill} />
      <path
        d="M230.616 5.26611L225.966 17.8431L221.316 5.26611H218.907L224.314 19.7479H227.591L232.997 5.26611H230.616Z"
        fill={fill}
      />
      <path
        d="M247.002 7.3949C245.629 5.96633 243.696 5.15401 241.623 5.15401C239.579 5.15401 237.674 5.8823 236.273 7.19882C234.817 8.57137 234.032 10.4481 234.032 12.5209C234.032 14.5938 234.845 16.4985 236.273 17.8431C237.674 19.1596 239.579 19.8879 241.623 19.8879C244.593 19.8879 247.282 18.2352 248.486 15.7142L246.553 14.7898C245.685 16.8067 243.808 18.0672 241.595 18.0672C238.906 18.0672 236.749 16.1904 236.329 13.4733V13.4173H249.158C249.242 11.0924 248.486 8.93551 247.002 7.3949ZM236.329 11.6246V11.5686C236.749 8.82347 238.878 6.97473 241.623 6.97473C244.368 6.97473 246.497 8.82347 246.918 11.5686V11.6246H236.329Z"
        fill={fill}
      />
      <path
        d="M176.808 5.26609H172.858V2.0168H170.589V5.26609H168.376V7.05881H170.589V19.7199L172.858 19.7479V7.05881H176.808V5.26609Z"
        fill={fill}
      />
      <path
        d="M64.4817 19.7479V12.521C64.4817 9.43978 63.3893 7.81513 60.8403 7.81513C59.5798 7.81513 58.5714 8.26331 57.787 9.13166C57.0027 10 56.6106 11.1204 56.6106 12.521V19.7479H53.4453V0.0280304H56.6106V7.59104C57.5069 6.02242 59.3837 5.07004 61.8767 5.07004C63.9215 5.07004 65.3781 5.71429 66.3024 6.9748C67.2268 8.2353 67.675 10.084 67.675 12.521V19.7479H64.4817Z"
        fill={fill}
      />
      <path
        d="M74.1448 5.26607V12.4929C74.1448 15.5742 75.1252 17.1708 77.4782 17.1708C78.7387 17.1708 79.7191 16.7506 80.4193 15.8823C81.1196 15.014 81.4557 13.8935 81.4557 12.4649V5.26607H84.593V19.7478H81.5678L81.4277 17.4789C80.4754 19.1596 78.7947 19.9999 76.4137 19.9719C74.4249 19.9719 72.9964 19.2997 72.156 17.9831C71.3157 16.6666 70.9516 14.9019 70.9516 12.549V5.26607H74.1448Z"
        fill={fill}
      />
      <path
        d="M110.867 7.05886C111.652 8.40339 112.016 10.1401 112.016 12.521V19.7479H108.85V12.521C108.85 9.43981 107.898 7.81516 105.573 7.81516C104.341 7.81516 103.388 8.23533 102.716 9.10367C102.016 9.97202 101.708 11.0925 101.708 12.521V19.7479H98.5424V12.521C98.5424 9.43981 97.59 7.81516 95.2651 7.81516C94.0326 7.81516 93.0802 8.23533 92.4079 9.10367C91.7076 9.97202 91.3715 11.0925 91.3715 12.521V19.7479H88.2063V5.26614H91.2034L91.3155 7.56306C92.1838 5.82637 93.7525 5.04205 96.3295 5.04205C98.7384 5.04205 100.307 6.07847 101.063 8.15129C101.988 6.1625 103.949 5.04205 106.694 5.04205C108.654 5.07006 110.055 5.74233 110.867 7.05886Z"
        fill={fill}
      />
      <path
        d="M127.954 7.39489C129.299 8.85147 130.055 11.0644 129.915 13.6134H118.038C118.235 14.7338 118.767 15.6582 119.579 16.3585C120.391 17.0587 121.344 17.4229 122.408 17.4229C124.285 17.4229 125.77 16.3865 126.526 14.6498L129.243 15.9663C128.627 17.1988 127.702 18.2072 126.47 18.9075C125.237 19.6078 123.893 19.9999 122.436 19.9999C120.391 19.9999 118.459 19.2716 117.086 17.9831C115.686 16.7226 114.817 14.7618 114.817 12.5489C114.817 10.3361 115.686 8.40329 117.086 7.11478C118.487 5.82627 120.391 5.09798 122.436 5.09798C124.621 5.06997 126.61 5.93831 127.954 7.39489ZM119.551 8.68341C118.767 9.38369 118.263 10.3081 118.038 11.4285H126.806C126.61 10.28 126.106 9.35567 125.293 8.68341C124.509 8.01114 123.529 7.64699 122.408 7.64699C121.288 7.64699 120.335 8.01114 119.551 8.68341Z"
        fill={fill}
      />
    </svg>
  )
}

export default Logo
