// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-guidelines-js": () => import("./../../../src/pages/guidelines.js" /* webpackChunkName: "component---src-pages-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-wellbeing-js": () => import("./../../../src/pages/wellbeing.js" /* webpackChunkName: "component---src-pages-wellbeing-js" */),
  "component---src-templates-standard-content-js": () => import("./../../../src/templates/standard-content.js" /* webpackChunkName: "component---src-templates-standard-content-js" */),
  "component---src-templates-use-cases-js": () => import("./../../../src/templates/use-cases.js" /* webpackChunkName: "component---src-templates-use-cases-js" */)
}

