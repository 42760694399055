import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'
import { zIndex } from './vars/zIndex.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  body {
    background-color: ${colors.light};

    &.disable-scroll {
      overflow: hidden;
      touch-action: none;
    }
  }

  button {
    &:active,
    &:hover {
      color: ${colors.dark};
    }
  }

  #portal {
    /* position: absolute; */
    z-index: ${zIndex.portal};
  }
`
