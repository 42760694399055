import React from 'react'
import PropTypes from 'prop-types'
import { GridItemMain } from './index.style'

const GridItem = ({
  children,
  tiny,
  mobile,
  mobileL,
  tabletP,
  tabletL,
  desk,
  deskL,
  contained,
  tinyStart,
  mobileStart,
  mobileLStart,
  tabletPStart,
  tabletLStart,
  deskStart,
  deskLStart,
  containedStart,
  tinyOrder,
  mobileOrder,
  mobileLOrder,
  tabletPOrder,
  tabletLOrder,
  deskOrder,
  deskLOrder,
  containedOrder,
}) => (
  <GridItemMain
    tiny={tiny}
    mobile={mobile}
    mobileL={mobileL}
    tabletP={tabletP}
    tabletL={tabletL}
    desk={desk}
    deskL={deskL}
    contained={contained}
    tinyStart={tinyStart}
    mobileStart={mobileStart}
    mobileLStart={mobileLStart}
    tabletPStart={tabletPStart}
    tabletLStart={tabletLStart}
    deskStart={deskStart}
    deskLStart={deskLStart}
    containedStart={containedStart}
    tinyOrder={tinyOrder}
    mobileOrder={mobileOrder}
    mobileLOrder={mobileLOrder}
    tabletPOrder={tabletPOrder}
    tabletLOrder={tabletLOrder}
    deskOrder={deskOrder}
    deskLOrder={deskLOrder}
    containedOrder={containedOrder}
  >
    {children}
  </GridItemMain>
)

GridItem.propTypes = {
  children: PropTypes.node,
  tiny: PropTypes.number,
  mobile: PropTypes.number,
  mobileL: PropTypes.number,
  tabletP: PropTypes.number,
  tabletL: PropTypes.number,
  desk: PropTypes.number,
  deskL: PropTypes.number,
  contained: PropTypes.number,
  tinyStart: PropTypes.number,
  mobileStart: PropTypes.number,
  mobileLStart: PropTypes.number,
  tabletPStart: PropTypes.number,
  tabletLStart: PropTypes.number,
  deskStart: PropTypes.number,
  deskLStart: PropTypes.number,
  containedStart: PropTypes.number,
  tinyOrder: PropTypes.number,
  mobileOrder: PropTypes.number,
  mobileLOrder: PropTypes.number,
  tabletPOrder: PropTypes.number,
  tabletLOrder: PropTypes.number,
  deskOrder: PropTypes.number,
  deskLOrder: PropTypes.number,
  containedOrder: PropTypes.number,
}

export default GridItem
