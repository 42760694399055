import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const LogoMobile = ({
  width = 91,
  height = 60,
  fill = colors.dark,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 91 60"
      aria-labelledby="mobileLogo"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id="mobileLogo">Hume</title>

      <path
        d="M3.67347 4.17238V1.54199H1.79138V4.17238H0V5.69165H1.79138V15.8957H3.67347V5.69165H6.87075V4.17238H3.67347Z"
        fill={fill}
      />
      <path
        d="M18.3671 10.0681C18.3671 7.347 17.3921 5.71435 14.8751 5.71435C12.4714 5.71435 10.7934 7.55108 10.7934 10.0681V15.8958H8.88867V6.10352e-05H10.7934V6.30392C11.5644 4.9207 13.1971 4.08169 15.4646 4.08169C18.9567 4.08169 20.2719 6.37194 20.2719 10.0681V15.8958H18.3671V10.0681Z"
        fill={fill}
      />
      <path
        d="M33.2427 5.89572C32.1089 4.71659 30.5669 4.08167 28.8663 4.08167C27.2109 4.08167 25.6463 4.67123 24.5125 5.75967C23.3334 6.87078 22.6758 8.41273 22.6758 10.0907C22.6758 11.7687 23.3334 13.3107 24.5125 14.4218C25.669 15.5102 27.2109 16.0998 28.8663 16.0998C31.2926 16.0998 33.4694 14.7619 34.4445 12.6984L32.8799 11.9501C32.1769 13.6055 30.635 14.6259 28.8663 14.6259C26.6894 14.6259 24.9207 13.0839 24.5805 10.9071V10.8617H35.0114C35.0567 8.88892 34.4445 7.14289 33.2427 5.89572ZM24.5579 9.34244V9.29708C24.898 7.07486 26.644 5.57826 28.8663 5.57826C31.0885 5.57826 32.8345 7.07486 33.1746 9.29708V9.34244H24.5579Z"
        fill={fill}
      />
      <path
        d="M10.7253 37.8911V32.0408C10.7253 29.5465 9.8409 28.2313 7.77741 28.2313C6.757 28.2313 5.94067 28.5941 5.30575 29.297C4.67083 30 4.35337 30.907 4.35337 32.0408V37.8911H1.79102V21.9047H4.35337V28.0272C5.079 26.7573 6.59827 25.9864 8.61641 25.9864C10.2717 25.9864 11.4509 26.5079 12.1992 27.5283C12.9475 28.5487 13.3103 30.0453 13.3103 32.0181V37.8684H10.7253V37.8911Z"
        fill={fill}
      />
      <path
        d="M18.5482 26.1679V32.0182C18.5482 34.5125 19.3418 35.8051 21.2466 35.8051C22.267 35.8051 23.0607 35.4649 23.6276 34.762C24.1945 34.059 24.4666 33.152 24.4666 31.9955V26.1452H27.0289V37.8686H24.5799L24.4666 36.0318C23.6956 37.3924 22.335 38.0726 20.4076 38.05C18.7976 38.05 17.6412 37.5057 16.9609 36.44C16.2806 35.3742 15.9858 33.9457 15.9858 32.0409V26.1679H18.5482Z"
        fill={fill}
      />
      <path
        d="M48.2763 27.6191C48.9112 28.7075 49.206 30.1134 49.206 32.0408V37.8912H46.6437V32.0408C46.6437 29.5465 45.8727 28.2313 43.9906 28.2313C42.9929 28.2313 42.2219 28.5715 41.6777 29.2744C41.1108 29.9774 40.8613 30.8844 40.8613 32.0408V37.8912H38.299V32.0408C38.299 29.5465 37.528 28.2313 35.6459 28.2313C34.6482 28.2313 33.8772 28.5715 33.333 29.2744C32.7661 29.9774 32.494 30.8844 32.494 32.0408V37.8912H29.9316V26.1678H32.3579L32.4713 28.0046C33.1743 26.5987 34.4441 25.9637 36.5303 25.9637C38.4804 25.9637 39.7502 26.8027 40.3625 28.4808C41.1108 26.8481 42.6754 25.9637 44.9203 25.9637C46.4849 25.9864 47.6187 26.5533 48.2763 27.6191Z"
        fill={fill}
      />
      <path
        d="M62.1086 27.8685C63.197 29.0476 63.8092 30.839 63.6959 32.9025H54.0813C54.2401 33.8095 54.6709 34.5578 55.3285 35.1247C55.9861 35.6916 56.7571 35.9864 57.6188 35.9864C59.138 35.9864 60.3398 35.1474 60.9521 33.7415L63.1516 34.8073C62.6528 35.805 61.9045 36.6213 60.9067 37.1882C59.909 37.7778 58.8206 38.0726 57.6414 38.0726C55.9861 38.0726 54.4215 37.483 53.3104 36.4399C52.1766 35.4195 51.4736 33.8322 51.4736 32.0408C51.4736 30.2494 52.1766 28.6848 53.3104 27.6417C54.4442 26.5986 55.9861 26.0091 57.6414 26.0091C59.4328 25.9864 61.0201 26.712 62.1086 27.8685ZM55.3058 28.9116C54.6709 29.4785 54.2627 30.2268 54.0813 31.1338H61.1788C61.0201 30.2041 60.612 29.4558 59.9544 28.9116C59.3194 28.3673 58.5258 28.0726 57.6188 28.0726C56.7117 28.0726 55.9408 28.3673 55.3058 28.9116Z"
        fill={fill}
      />
      <path d="M3.87775 48.1409H2.04102V59.8643H3.87775V48.1409Z" fill={fill} />
      <path
        d="M2.97043 43.8779C2.33551 43.8779 1.81396 44.3994 1.81396 45.0343C1.81396 45.6919 2.33551 46.2135 2.97043 46.2135C3.60535 46.2135 4.12689 45.6919 4.12689 45.0343C4.12689 44.3994 3.60535 43.8779 2.97043 43.8779Z"
        fill={fill}
      />
      <path
        d="M17.6189 59.8641V54.0364C17.6189 52.0863 17.3014 50.6351 16.6212 49.66C15.8956 48.5943 14.7618 48.05 13.1518 48.05C11.0429 48.05 9.61437 48.7757 8.75269 50.2496L8.68466 50.3856L8.61664 48.1408H6.87061V59.8641H8.70734V54.0364C8.70734 52.7439 9.04748 51.6555 9.70507 50.8618C10.3853 50.0455 11.3831 49.6147 12.5849 49.6147C14.7844 49.6147 15.8275 51.0206 15.8275 54.0364V59.8641H17.6189Z"
        fill={fill}
      />
      <path d="M22.3587 48.1408H20.522V59.8642H22.3587V48.1408Z" fill={fill} />
      <path
        d="M21.4514 43.8777C20.8165 43.8777 20.2949 44.3992 20.2949 45.0341C20.2949 45.6917 20.8165 46.2133 21.4514 46.2133C22.0863 46.2133 22.6078 45.6917 22.6078 45.0341C22.6078 44.3992 22.0863 43.8777 21.4514 43.8777Z"
        fill={fill}
      />
      <path d="M35.6009 48.1408H33.7642V59.8642H35.6009V48.1408Z" fill={fill} />
      <path
        d="M34.6711 43.8777C34.0362 43.8777 33.5146 44.3992 33.5146 45.0341C33.5146 45.6917 34.0362 46.2133 34.6711 46.2133C35.306 46.2133 35.8276 45.6917 35.8276 45.0341C35.8502 44.3992 35.3287 43.8777 34.6711 43.8777Z"
        fill={fill}
      />
      <path
        d="M48.4808 48.1408L48.4127 50.3857L48.3447 50.2496C47.551 48.8664 45.7823 48.05 43.6055 48.05C42.0635 48.05 40.6349 48.6623 39.5919 49.7507C38.5261 50.8618 37.9365 52.3811 37.9365 54.0138C37.9365 55.6464 38.5261 57.1657 39.5919 58.2768C40.6576 59.3879 42.0862 59.9775 43.6055 59.9775C46.0318 59.9775 47.6191 59.2292 48.3447 57.7779L48.4127 57.6419L48.4808 59.8414H50.2268V48.1181H48.4808V48.1408ZM44.059 58.4582C41.6327 58.4582 39.7279 56.5081 39.7279 54.0364C39.7279 51.5648 41.6327 49.6147 44.059 49.6147C46.4853 49.6147 48.4127 51.5648 48.4127 54.0364C48.4127 56.5081 46.508 58.4582 44.059 58.4582Z"
        fill={fill}
      />
      <path
        d="M59.7053 48.1407H56.508V45.5103H54.6713V48.1407H52.8799V49.5919H54.6713V59.8641L56.508 59.8414V49.5919H59.7053V48.1407Z"
        fill={fill}
      />
      <path
        d="M62.676 43.8777C62.0411 43.8777 61.5195 44.3992 61.5195 45.0341C61.5195 45.6917 62.0411 46.2133 62.676 46.2133C63.3109 46.2133 63.8325 45.6917 63.8325 45.0341C63.8325 44.3992 63.3109 43.8777 62.676 43.8777Z"
        fill={fill}
      />
      <path d="M63.5833 48.1408H61.7466V59.8642H63.5833V48.1408Z" fill={fill} />
      <path
        d="M75.2605 48.1407L71.519 58.3222L67.7548 48.1407H65.8047L70.1811 59.8641H72.8342L77.2106 48.1407H75.2605Z"
        fill={fill}
      />
      <path
        d="M88.5492 49.8641C87.4381 48.7076 85.8734 48.05 84.1954 48.05C82.5401 48.05 80.9981 48.6396 79.8644 49.7054C78.6852 50.8165 78.0503 52.3358 78.0503 54.0138C78.0503 55.6918 78.7079 57.2337 79.8644 58.3222C80.9981 59.3879 82.5401 59.9775 84.1954 59.9775C86.5991 59.9775 88.7759 58.6396 89.751 56.5988L88.1864 55.8505C87.4834 57.4832 85.9641 58.5036 84.1728 58.5036C81.9959 58.5036 80.2498 56.9843 79.9097 54.7847V54.7394H90.2952C90.3632 52.8346 89.751 51.1113 88.5492 49.8641ZM79.9097 53.2881V53.2428C80.2498 51.0206 81.9732 49.524 84.1954 49.524C86.4177 49.524 88.141 51.0206 88.4812 53.2428V53.2881H79.9097Z"
        fill={fill}
      />
      <path
        d="M31.7228 48.1407H28.5029V45.5103H26.6888V48.1407H24.8975V49.5919H26.6888V59.8414L28.5029 59.8641V49.5919H31.7228V48.1407Z"
        fill={fill}
      />
    </svg>
  )
}

export default LogoMobile
