import React, { useReducer, createContext } from 'react'

export const StoreState = createContext()
export const StoreDispatch = createContext()

const initialState = {
  showTransitionMask: false,
  mobileNavIsOpen: false,
  contactIsOpen: false,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  popstate: 0,
  circleFill: false,
  dragCursor: false,
  modalIsOpen: 0,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.payload,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SHOW_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: true,
      }
    }
    case 'HIDE_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: false,
      }
    }

    case 'SHOW_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: true,
      }
    }
    case 'HIDE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: false,
      }
    }
    case 'TOGGLE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: !state.mobileNavIsOpen,
      }
    }

    case 'SHOW_CONTACT': {
      return {
        ...state,
        contactIsOpen: true,
      }
    }
    case 'HIDE_CONTACT': {
      return {
        ...state,
        contactIsOpen: false,
      }
    }

    case 'SHOW_MODAL': {
      return {
        ...state,
        modalIsOpen: 1,
      }
    }
    case 'HALF_SHOW_MODAL': {
      return {
        ...state,
        modalIsOpen: 0.5,
      }
    }

    case 'HIDE_MODAL': {
      return {
        ...state,
        modalIsOpen: 0,
      }
    }

    case 'CIRCLE_GROW': {
      return {
        ...state,
        circleFill: true,
      }
    }

    case 'CIRCLE_SHRINK': {
      return {
        ...state,
        circleFill: false,
      }
    }

    case 'SHOW_DRAG_CURSOR': {
      return {
        ...state,
        dragCursor: true,
      }
    }

    case 'HIDE_DRAG_CURSOR': {
      return {
        ...state,
        dragCursor: false,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

const Store = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StoreState.Provider value={state}>
      <StoreDispatch.Provider value={dispatch}>
        {props.children}
      </StoreDispatch.Provider>
    </StoreState.Provider>
  )
}

export default Store
