import React, { useContext } from 'react'
import { StoreState, StoreDispatch } from '../../Store'

// Components
import Button from '../Button/index'

import { GetInTouchWrapper } from './index.style'

const GetInTouch = ({ hasborder, narrow, text }) => {
  const store = useContext(StoreState),
    dispatch = useContext(StoreDispatch)

  const { contactIsOpen } = store

  return (
    <GetInTouchWrapper>
      <Button
        text={text ? text : `Get In Touch`}
        to="/"
        hasborder={hasborder ? hasborder : false}
        narrow={narrow ? narrow : false}
        theme={4}
        event={() => {
          if (contactIsOpen) {
            if (
              document
                .querySelector('body')
                .classList.contains('disable-scroll')
            ) {
              document.querySelector('body').classList.remove('disable-scroll')
            }
            dispatch({ type: 'CIRCLE_SHRINK' })
            dispatch({ type: 'HIDE_CONTACT' })
            dispatch({ type: 'HIDE_MODAL' })
          } else {
            dispatch({ type: 'HIDE_MOBILE_NAV' })
            dispatch({ type: 'CIRCLE_GROW' })
            dispatch({ type: 'SHOW_CONTACT' })
            dispatch({ type: 'HALF_SHOW_MODAL' })

            setTimeout(() => {
              document.querySelector('body').classList.add('disable-scroll')
            }, 500)
          }
        }}
      ></Button>
    </GetInTouchWrapper>
  )
}

export default GetInTouch
