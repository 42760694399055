import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

export const Button = styled.div`
  margin-top: 5.2rem;
  font-family: ${font.secondary.family};
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: currentColor;

  ${mq.tabletL} {
    font-size: 1.6rem;
  }

  a {
    display: inline-block;
  }

  span {
    display: inline-block;
  }

  a,
  button {
    background: transparent;
    cursor: pointer;
    font-family: ${font.secondary.family};
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: currentColor;

    ${({ hasborder, theme }) => {
      return hasborder === 'true' || hasborder === true
        ? `
        background: transparent;
        border:  0.1rem solid ${colors.dark};
        border-radius: 10rem;

        &:after {
          display: none;
        }

        &:hover {

          background: ${colors.light};
          border: 0.1rem solid ${theme};
        }
        `
        : `
        border: none;
        position: relative;

          &:after {
            background-color: ${colors.dark};
            bottom: .5rem;
            content: '';
            display: inline-block;
            height: .1rem;
            left: 0.5rem;
            right: 0.5rem;
            position: absolute;
            transform-origin: left;
            transform: scaleX(0);
            transition: transform 1s ${easings.modal.default};
          }

        &:hover {
         &:after {
            transform: scaleX(1);
         }
        }
        `
    }}

    overflow: hidden;
    padding: 1.1rem 1.5rem;
    transition: border-color 1s ${easings.modal.default},
      background-color 1s ${easings.modal.default};
  }

  ${mq.tabletL} {
    ${({ narrow }) => {
      return narrow === true
        ? `
        a,button{
      padding: .65rem 1.5rem;}`
        : `a,button{
      padding: 2.1rem 3rem;}`
    }}
  }
`
