import React from 'react'
import {
  Super,
  Heading1,
  Heading2,
  Heading3,
  Title,
  TitleSmall,
  TextBodyBold,
  TextBody,
} from '../../styles/vars/textStyles.style'

const ThisSuper = ({ children, as, color, maxWidth, ...props }) => (
  <Super as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Super>
)

const ThisHeading1 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading1 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading1>
)

const ThisHeading2 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading2 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading2>
)

const ThisHeading3 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading3 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading3>
)

const ThisTitle = ({ children, as, color, maxWidth, ...props }) => (
  <Title as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Title>
)

const ThisTitleSmall = ({ children, as, color, maxWidth, ...props }) => (
  <TitleSmall as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TitleSmall>
)

const ThisTextBodyBold = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodyBold as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodyBold>
)

const ThisTextBody = ({ children, as, color, maxWidth, ...props }) => (
  <TextBody as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBody>
)

export {
  ThisSuper as Super,
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisTitle as Title,
  ThisTitleSmall as TitleSmall,
  ThisTextBodyBold as TextBodyBold,
  ThisTextBody as TextBody,
}
