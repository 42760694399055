import styled from 'styled-components'
import { css } from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'

export const textStylesConfig = {
  super: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 96,
      max: 220,
    },
    lineHeight: 1,
    letterSpacing: -0.03,
  },
  heading1: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 36,
      max: 78,
    },
    lineHeight: 1.05,
    letterSpacing: -0.05,
  },
  heading2: {
    family: font.primary.family,
    weight: font.primary.weight.bold,
    size: {
      min: 30,
      max: 60,
    },
    lineHeight: 1.1,
    letterSpacing: -0.05,
  },
  heading3: {
    family: font.secondary.family,
    weight: font.secondary.weight.semibold,
    size: {
      min: 20,
      max: 30,
    },
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  title: {
    family: font.primary.family,
    weight: font.primary.weight.semibold,
    size: {
      min: 18,
      max: 20,
    },
    lineHeight: 1.25,
    letterSpacing: -0.01,
  },
  titleSmall: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.25,
  },
  bodyBold: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 16,
      max: 19,
    },
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  body: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 18,
      max: 24,
    },
    lineHeight: 1.2,
    letterSpacing: 0,
  },
}

const getStyles = style => `
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${clamp('font-size', style.size.min, style.size.max)}
  ${style.uppercase ? 'text-transform: uppercase' : ''};
`

const SuperStyles = css`
  ${getStyles(textStylesConfig.super)}
`

const Heading1Styles = css`
  ${getStyles(textStylesConfig.heading1)}
`

const Heading2Styles = css`
  ${getStyles(textStylesConfig.heading2)}
`

const Heading3Styles = css`
  ${getStyles(textStylesConfig.heading3)}
`

const TitleStyles = css`
  ${getStyles(textStylesConfig.title)}
`

const TitleSmallStyles = css`
  ${getStyles(textStylesConfig.titleSmall)}
`

const TextBodyBoldStyles = css`
  ${getStyles(textStylesConfig.bodyBold)}
`

const TextBodyStyles = css`
  ${getStyles(textStylesConfig.body)}
`

export const Super = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${SuperStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading1 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${Heading1Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading2 = styled.p`
  margin-bottom: 4rem;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${Heading2Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading3 = styled.p`
  margin-bottom: 4rem;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${Heading3Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Title = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TitleStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TitleSmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TitleSmallStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyBold = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TextBodyBoldStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBody = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TextBodyStyles}
  color: ${props => (props.color ? props.color : undefined)};
`
