import React, { useContext } from 'react'
import { StoreState } from '../../Store/index'
import { ContainerMain } from './index.style'

const Container = ({ children }) => {
  const state = useContext(StoreState),
    { modalIsOpen } = state

  return <ContainerMain modalIsOpen={modalIsOpen}>{children}</ContainerMain>
}

export default Container
