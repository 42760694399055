import React, { useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { StoreDispatch, StoreState } from '../../Store'
import { TransitionMaskWrapper, TransitionMaskBlock } from './index.style'
import { animation } from '../../styles/vars/animation.style'

const TransitionMask = () => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)
  const maskRef = useRef(),
    timeoutRef = useRef()

  useEffect(() => {
    const tl = gsap.timeline()

    if (store.showTransitionMask) {
      const mask = maskRef.current

      // console.log(store.popstate)

      if (store.popstate > 0) {
        dispatch({ type: 'SHOW_MODAL' })
        dispatch({ type: 'CIRCLE_GROW' })
      }
      tl.to(mask, {
        opacity: 1,
        duration: animation.maskShowDuration,
        ease: 'power2.inOut',
        onComplete: () => {
          dispatch({ type: 'CIRCLE_SHRINK' })
          dispatch({ type: 'HIDE_MOBILE_NAV' })
          timeoutRef.current = setTimeout(() => {
            dispatch({ type: 'HIDE_MODAL' })
          }, 500)
        },
      }).to(mask, {
        opacity: 0,
        duration: animation.maskHideDuration,
        ease: 'power2.inOut',
        onComplete: () => {
          dispatch({ type: 'CIRCLE_SHRINK' })
          dispatch({ type: 'HIDE_TRANSITION_MASK' })
          timeoutRef.current = setTimeout(() => {
            dispatch({ type: 'HIDE_MODAL' })
          }, 500)
        },
      })
    }

    return () => {
      if (tl) {
        tl.kill()
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [store.showTransitionMask, dispatch, store.popstate])

  return (
    <TransitionMaskWrapper show={store.showTransitionMask}>
      <TransitionMaskBlock ref={maskRef} />
    </TransitionMaskWrapper>
  )
}

export default TransitionMask
