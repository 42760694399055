import styled from 'styled-components'
import TransitionLink from 'gatsby-plugin-transition-link'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { easings } from '../../styles/vars/easings.style'

export const PageTransitionLinkMain = styled(TransitionLink)`
  border: none;
  display: inline-flex;
  font-family: ${font.secondary.family};
  font-weight: 600;
  letter-spacing: 0.01em;
  transition: border-color 1s ${easings.modal.default},
    background-color 1s ${easings.modal.default};

  ${({ hasborder, theme, underline, padded }) => {
    return hasborder === 'true'
      ? `
          background: transparent;
      border:  .1rem solid ${colors.dark};
      border-radius: 10rem;

        &:hover {
          background: ${colors.light};
          border:  .1rem solid ${theme};
        }`
      : `
        position: relative;

          &:after {
            background-color: ${underline ? `${colors.dark}` : `none`};
            bottom: .5rem;
            content: '';
            display: inline-block;
            height: .1rem;
            left: 1.5rem;
            right: 1.5rem;
            position: absolute;
            transform-origin: left;
            transform: scaleX(0);
            transition: transform 1s ${easings.modal.default};
          }

      &:hover {
         &:after {
            transform: scaleX(1);
         }
        }
      `
  }}

  padding: ${props => props.padded === 'false' ? '0' : '.6rem 1.5rem'};

  ${({ fill }) => {
    if (fill) {
      return `
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          height: auto;
        }
      `
    }
  }}
`
