import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StoreDispatch, StoreState } from '../../Store'
import { animation } from '../../styles/vars/animation.style'
import { PageTransitionLinkMain } from './index.style'
import { LastPageContext } from "../ReformProviders/Providers";


const PageTransitionLink = ({
  children,
  hasborder,
  theme,
  to,
  fill,
  onClick,
  underline,
  padded,
  ...props
}) => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)
  const { setLastPageURL }= useContext(LastPageContext)

  useEffect(() => {
    setLastPageURL(window.location.pathname)
  }, [])

  const colourArray = ['#cc9f68', '#c88885', '#be9cc1', '#92a8bc', '#92babc']

  return (
    <PageTransitionLinkMain
      to={to}
      exit={{
        delay: animation.maskShowDuration,
        length: 0,
        zIndex: 2,
      }}
      entry={{
        delay: 0,
        length: animation.maskHideDuration,
        zIndex: 1,
      }}
      fill={fill ? 'true' : undefined}
      {...props}
      onClick={() => {
        if (onClick) {
          onClick()
        }

       if (
          to.indexOf(store.pathname) === -1 ||
          to.length !== store.pathname.length
        ) {
          dispatch({ type: 'UPDATE_PATHNAME', payload: to })
          dispatch({ type: 'SHOW_TRANSITION_MASK' })
        }
      }}
      hasborder={hasborder}
      underline={underline}
      padded={padded}
      theme={colourArray[theme]}
    >
      {children}
    </PageTransitionLinkMain>
  )
}

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
}

PageTransitionLink.defaultProps = {
  fill: false,
}

export default PageTransitionLink
