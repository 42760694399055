import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const LogoDots = ({
  width = 31,
  height = 31,
  fill = colors.dark,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 31"
      aria-labelledby="logoTitle"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id="logoTitle">Hume</title>
      <path
        fill={fill}
        d="M2.213 16.462c-1.546.352-2.508 1.801-2.131 3.355.355 1.553 1.859 2.464 3.384 2.112 1.546-.352 2.486-1.802 2.131-3.355-.355-1.553-1.86-2.464-3.384-2.112zM3.55 10.6c1.233.974 2.967.808 3.97-.434 1.002-1.243.773-2.962-.44-3.935a2.806 2.806 0 00-3.969.435c-1.002 1.242-.773 2.961.44 3.935zM11.03 25.325c-1.441-.683-3.092-.103-3.781 1.305-.69 1.408-.126 3.044 1.316 3.749 1.442.683 3.092.103 3.782-1.305.668-1.43.125-3.065-1.317-3.749zM19.723 25.326a2.76 2.76 0 00-1.316 3.748c.69 1.408 2.319 2.009 3.782 1.305a2.76 2.76 0 001.316-3.749c-.69-1.408-2.34-2.009-3.782-1.304zM28.538 16.462c-1.546-.352-3.03.559-3.385 2.112s.585 3.024 2.131 3.355c1.546.352 3.03-.56 3.385-2.112.355-1.554-.585-3.003-2.131-3.355zM27.596 10.6c1.232-.973 1.441-2.692.438-3.934-1.002-1.243-2.736-1.409-3.97-.435-1.232.973-1.44 2.692-.438 3.935 1.003 1.242 2.737 1.408 3.97.435zM15.377.805c-1.63 0-2.842 1.242-2.842 2.816 0 1.574 1.212 2.817 2.841 2.817 1.61 0 2.842-1.243 2.842-2.817S16.985.805 15.377.805z"
      />
    </svg>
  )
}

export default LogoDots
