import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import { StoreState, StoreDispatch } from '../../Store'
import { disablePageScroll, enablePageScroll } from '../../utils/utils'
import {
  MenuToggleMain,
  MenuToggleBar,
  MenuToggleWrapper,
  MenuCircle,
} from './index.style'

const MenuToggle = () => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)

  const { mobileNavIsOpen } = store
  const toggleRef = useRef()
  const barRefA = useRef()
  const barRefB = useRef()

  useEffect(() => {
    const tlOpen = gsap.timeline({ paused: true })
    const tlClose = gsap.timeline({ paused: true })

    tlOpen
      .to(barRefA.current, {
        y: 0,
        // rotate: 45,
        duration: 0.2,
        ease: 'power2.inOut',
      })
      .to(
        barRefB.current,
        {
          y: 0,
          // rotate: -45,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )

    tlClose
      .to(barRefA.current, {
        // y: -4,
        y: 0,
        rotate: 0,
        duration: 0.2,
        ease: 'power2.inOut',
      })
      .to(
        barRefB.current,
        {
          // y: 4,
          y: 0,
          rotate: 0,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )

    if (mobileNavIsOpen) {
      disablePageScroll()
      tlOpen.play()
    } else {
      enablePageScroll()
      tlClose.play()
    }

    return () => {
      tlOpen.kill()
      tlClose.kill()
    }
  }, [mobileNavIsOpen])

  const handleClick = () => {
    if (mobileNavIsOpen) {
      if (window.innerWidth <= 1024) {
        if (
          document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.remove('disable-scroll')
        }
      }
      dispatch({ type: 'HIDE_MOBILE_NAV' })
      dispatch({ type: 'CIRCLE_SHRINK' })
      dispatch({ type: 'HIDE_MODAL' })
    } else {
      if (window.innerWidth <= 1024) {
        if (
          !document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.add('disable-scroll')
        }
      }
      dispatch({ type: 'SHOW_MOBILE_NAV' })
      dispatch({ type: 'CIRCLE_GROW' })
      dispatch({ type: 'SHOW_MODAL' })
    }
  }

  return (
    <MenuToggleWrapper ref={toggleRef}>
      <MenuToggleMain onClick={handleClick} aria-label="Toggle menu">
        <MenuToggleBar show={!store.mobileNavIsOpen} ref={barRefA}>
          <MenuCircle />
        </MenuToggleBar>

        <MenuToggleBar show={store.mobileNavIsOpen} ref={barRefB}>
          Close
        </MenuToggleBar>
      </MenuToggleMain>
    </MenuToggleWrapper>
  )
}

export default MenuToggle
