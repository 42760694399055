import React, { useEffect, useState, createContext } from "react";
import { desktop, tablet, mobile } from '/src/styles/ReformStyles/media';



export const LastPageContext = createContext({
  lastPageURL: '/',
  setLastPageURL: () => {},
});

export const ScreenContext = createContext({
  fullWidth: false,
  desktop: false,
  tablet: false,
  mobile: false,
});

const Providers = ({ children }) => {
  const [lastPageURL, setLastPageURL] = useState('/')

  const [screen, setScreen] = useState({
    fullWidth: false,
    desktop: false,
    tablet: false,
    mobile: false,
  });

  useEffect(() => {
    const calcScreen = () => {
      setScreen({
        fullWidth: window.innerWidth > desktop,
        desktop: window.innerWidth > tablet && window.innerWidth <= desktop,
        tablet: window.innerWidth > mobile && window.innerWidth <= tablet,
        mobile: window.innerWidth <= mobile,
      });
    };

    calcScreen();

    window.addEventListener("resize", calcScreen);

    return () => {
      window.removeEventListener("resize", calcScreen);
    };
  }, []);

  return (
    <LastPageContext.Provider value={{lastPageURL, setLastPageURL}}>
      <ScreenContext.Provider value={screen}>
          {children}   
      </ScreenContext.Provider>
    </LastPageContext.Provider>
  );
};

export default Providers;