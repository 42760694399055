import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { isMobile } from 'react-device-detect'
import ResourceTracker from './ResourceTracker'
import { SceneCanvas, SceneWrapper } from './index.style'
import { debounce } from '../../utils/utils'
import fragmentShader from 'raw-loader!glslify-loader!./fragment.glsl'

class WebGL extends React.Component {
  constructor(props) {
    super(props)

    this.resTracker = new ResourceTracker()
    this.track = this.resTracker.track.bind(this.resTracker)
    // this.stats = null
    this.debug = this.props.debug
    this.pixelRatioMatch =
      typeof window !== `undefined`
        ? window.matchMedia('screen and (min-resolution: 2dppx)')
        : null
    this.DOM = {
      wrapper: createRef(),
      canvas: createRef(),
    }
    this.resizeOnLocationChange = true
    // Whether to add a buffer to the height of the canvas to account for changing browser size
    this.verticalBuffer = isMobile
    this.trailScroll = false
    this.scrollPos = 0
    this.scrollPosLerp = 0
    this.path = this.props.location.pathname
  }

  componentDidMount() {
    this.wrapper = this.props.useWindow ? window : this.DOM.wrapper.current

    this.setScene()
    this.setSizes()
    this.setCamera()
    this.setRenderer()
    this.setObjects()

    // if (this.debug) {
    //   this.setStats()
    // }

    this.start()
    setTimeout(this.showScene.bind(this), 1000)
  }

  componentWillUnmount() {
    if (this.resTracker) this.resTracker.dispose()
    if (this.renderer) this.renderer.dispose()

    window.cancelAnimationFrame(this.raf)
    window.removeEventListener('resize', this.stop)
    window.removeEventListener('resize', this.restartDebounced)

    try {
      this.pixelRatioMatch.removeEventListener('change', this.stop)
      this.pixelRatioMatch.removeEventListener('change', this.restartDebounced)
    } catch (error) {
      try {
        this.pixelRatioMatch.removeListener(this.stop)
        this.pixelRatioMatch.removeListener(this.restartDebounced)
      } catch (secondaryError) {
        console.error(secondaryError)
      }
    }
  }

  componentDidUpdate() {
    if (this.resizeOnLocationChange && this.path !== this.props.location.pathname) {
      // this.stop()
      // this.restart()
      this.setSizes()
      this.path = this.props.location.pathname
    }
  }

  // setStats() {
  //   if (typeof document !== 'undefined') {
  //     this.stats = new Stats()
  //     document.body.appendChild(this.stats.dom)
  //   }
  // }

  getPixelRatio() {
    return Math.min(window.devicePixelRatio, 2)
  }

  setScene() {
    this.scene = new THREE.Scene()
  }

  hideScene() {
    if (this.DOM.canvas.current) this.DOM.canvas.current.style.display = 'none'
  }

  showScene() {
    if (this.DOM.canvas.current) this.DOM.canvas.current.style.display = 'block'
  }

  updateScene(time) {
    // Update time
    this.bufferMaterial.uniforms.u_time.value++

    // Update circle pos
    this.bufferMaterial.uniforms.u_mousePos.value.x = this.props.x
    this.bufferMaterial.uniforms.u_mousePos.value.y = this.props.y
    this.bufferMaterial.uniforms.u_speed.value = this.props.velocity

    // Update scroll
    if (this.trailScroll) {
      this.scrollPos =
        window.scroll.scroll.instance.scroll.y / this.sizes.scrollable
      this.scrollPosLerp += (this.scrollPos - this.scrollPosLerp) * 0.3
    }

    // Update uniforms
    this.bufferMaterial.uniforms.u_pagePos.value =
      (this.props.y + window.scroll.scroll.instance.scroll.y) /
      this.sizes.fullPage
    if (this.trailScroll)
      this.bufferMaterial.uniforms.u_scrollPos.value = this.scrollPosLerp
    this.bufferMaterial.uniforms.u_filled.value = this.props.fillAmount

    // console.log(this.bufferMaterial.uniforms.u_pagePos.value)

    // Handle buffer scene
    this.renderer.setRenderTarget(this.textureB)
    this.renderer.render(this.bufferScene, this.camera)

    // Swap texture variables
    let temp = this.textureA
    this.textureA = this.textureB
    this.textureB = temp

    // Swap the visible material
    this.quad.material.map = this.textureB.texture
    // Swap the buffer material
    this.bufferMaterial.uniforms.u_bufferTexture.value = this.textureA.texture

    // Reset the render target ready for the actual render
    this.renderer.setRenderTarget(null)
  }

  setSizes() {
    let pageHeight = document.querySelector('#scroll-container').offsetHeight

    this.sizes = {
      width: this.DOM.wrapper.current.offsetWidth,
      height: this.trailScroll
        ? pageHeight
        : this.DOM.wrapper.current.offsetHeight +
          (this.verticalBuffer ? 200 : 0),
      fullPage: pageHeight,
      scrollable: pageHeight - this.DOM.wrapper.current.offsetHeight,
    }

    this.stop = () => {
      if (this.animating) {
        this.hideScene()
        this.animating = false
        this.resTracker.dispose()
        if (this.raf) window.cancelAnimationFrame(this.raf)
      }
    }

    this.resizeHandler = () => {
      let pageHeight = document.querySelector('#scroll-container').offsetHeight

      // Update sizes
      this.sizes.width = this.DOM.wrapper.current.offsetWidth
      this.sizes.height = this.trailScroll
        ? pageHeight
        : this.DOM.wrapper.current.offsetHeight
      this.sizes.fullPage = pageHeight
      this.sizes.scrollable =
        this.sizes.fullPage - this.DOM.wrapper.current.offsetHeight

      // Update camera
      this.camera.left = this.sizes.width / -2
      this.camera.right = this.sizes.width / 2
      this.camera.top = this.sizes.height / 2
      this.camera.bottom = this.sizes.height / -2
      this.camera.updateProjectionMatrix()

      // Update renderer
      this.renderer.setSize(this.sizes.width, this.sizes.height)
      this.renderer.setPixelRatio(this.getPixelRatio())
      if (this.trailScroll) this.renderer.domElement.style.position = 'absolute'
    }

    this.restartDebounced = debounce(() => this.restart(), 1000, false)

    if (!isMobile) {
      window.addEventListener('resize', this.stop)
      window.addEventListener('resize', this.restartDebounced)

      try {
        this.pixelRatioMatch.addEventListener('change', this.stop)
        this.pixelRatioMatch.addEventListener('change', this.restartDebounced)
      } catch (error) {
        try {
          this.pixelRatioMatch.addListener(this.stop)
          this.pixelRatioMatch.addListener(this.restartDebounced)
        } catch (error2) {
          console.error(error2)
        }
      }
    }
  }

  restart() {
    this.resizeHandler()
    this.setupTextures()
    this.start()
    setTimeout(this.showScene.bind(this), 500)
  }

  start() {
    this.animating = true
    this.raf = window.requestAnimationFrame(this.renderLoop.bind(this))
  }

  setCamera() {
    this.camera = new THREE.OrthographicCamera(
      this.sizes.width / -2,
      this.sizes.width / 2,
      this.sizes.height / 2,
      this.sizes.height / -2,
      1,
      1000
    )
    this.camera.position.x = 0
    this.camera.position.y = 0
    this.camera.position.z = 2
    this.scene.add(this.camera)
  }

  createBufferTextures() {
    this.textureA = this.track(
      new THREE.WebGLRenderTarget(this.sizes.width, this.sizes.height, {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.NearestFilter,
      })
    )
    this.textureB = this.track(
      new THREE.WebGLRenderTarget(this.sizes.width, this.sizes.height, {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.NearestFilter,
      })
    )
  }

  setupTextures() {
    // Create buffer scene
    this.bufferScene = new THREE.Scene()

    // Create buffer textures
    this.createBufferTextures()

    // Pass textureA to a shader material
    this.bufferMaterial = this.track(
      new THREE.ShaderMaterial({
        uniforms: {
          // The time
          u_time: { type: 'f', value: 0 },
          // The previous texture (or in this first pass, an empty texture)
          u_bufferTexture: { type: 't', value: this.textureA.texture },
          // The screen size
          u_res: {
            type: 'v2',
            value: new THREE.Vector2(this.sizes.width, this.sizes.height),
          },
          // The scrollable height of the page
          u_scrollable: { type: 'f', value: this.sizes.scrollable },
          // The mouse position (offscreen to start)
          u_mousePos: {
            type: 'v2',
            value: new THREE.Vector2(this.props.x, this.props.y),
          },
          // The colors of the circle
          u_circleColorStart: {
            type: 'v3',
            value: new THREE.Vector3(0.988, 0.694, 0.627),
          },
          u_circleColorMiddle: {
            type: 'v3',
            value: new THREE.Vector3(0.933, 0.765, 0.949),
          },
          u_circleColorEnd: {
            type: 'v3',
            value: new THREE.Vector3(0.718, 0.855, 0.925),
          },
          // Whether the circle fills the screen. Range 0 - 1
          u_filled: { type: 'f', value: 1 },
          // The velocity of the circle
          u_speed: { type: 'f', value: 0 },
          // The position of the cursor on the page. Range 0 - 1
          u_pagePos: { type: 'f', value: 0 },
          // The scroll position of the page. Range 0 - 1
          u_scrollPos: { type: 'f', value: 0 },
        },
        fragmentShader: fragmentShader,
        transparent: true,
      })
    )

    // Create plane
    this.plane = this.track(
      new THREE.PlaneBufferGeometry(this.sizes.width, this.sizes.height)
    )

    // Add the buffer shader material to the plane and add to the buffer scene
    this.bufferObject = this.track(
      new THREE.Mesh(this.plane, this.bufferMaterial)
    )
    this.bufferScene.add(this.bufferObject)

    // Draw textureB to a basic material to be shown on screen
    this.finalMaterial = this.track(
      new THREE.MeshBasicMaterial({
        map: this.textureB.texture,
        transparent: true,
      })
    )
    // Add the buffer final material to the plane and add to the visible scene
    this.quad = this.track(new THREE.Mesh(this.plane, this.finalMaterial))

    this.finalMaterial2 = this.track(
      new THREE.MeshBasicMaterial({ color: 0xff0000 })
    )
    this.quad2 = this.track(new THREE.Mesh(this.plane, this.finalMaterial2))

    this.scene.add(this.quad)
    this.scene.add(this.quad2)
  }

  setObjects() {
    this.setupTextures()
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.DOM.canvas.current,
      alpha: true,
    })

    this.gl = this.DOM.canvas.current.getContext('webgl')

    this.renderer.autoClear = false
    this.renderer.setSize(this.sizes.width, this.sizes.height)
  }

  renderLoop(time) {
    // if (this.stats) this.stats.update()

    this.updateScene(time)
    this.renderer.render(this.scene, this.camera)
    if (this.animating)
      this.raf = window.requestAnimationFrame(this.renderLoop.bind(this))
  }

  render() {
    const refs = this.DOM

    return (
      <>
        <SceneWrapper ref={refs.wrapper}>
          <SceneCanvas ref={refs.canvas} />
        </SceneWrapper>
      </>
    )
  }
}

export default WebGL

WebGL.propTypes = {
  useWindow: PropTypes.bool,
  debug: PropTypes.bool,
}

WebGL.defaultProps = {
  useWindow: true,
  debug: false,
}
