import React, { useContext, useEffect, useRef, useState } from 'react'

// Components
import Button from '../Button/index'
import Container from '../Container/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'

// Context

import { StoreState, StoreDispatch } from '../../Store'

import {
  Contact,
  ContactInner,
  Close,
  CloseButton,
  Content,
  ContactForm,
  ContactInput,
  ContactTextArea,
  ContactLabel,
  FeedbackMessage,
} from './index.style'
import { Heading2 } from '../TextStyles'
// import FocusTrap from 'focus-trap-react'

const ContactModal = () => {
  const store = useContext(StoreState),
    dispatch = useContext(StoreDispatch)

  const [feedbackMessage, setFeedbackMessage] = useState(null),
    [formFields, setFormFields] = useState({
      name: '',
      email: '',
      message: '',
    })

  const contactRef = useRef(null),
    formRef = useRef(null),
    nameRef = useRef(null),
    emailRef = useRef(null),
    messageRef = useRef(null)

  const { contactIsOpen } = store

  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      if (key === 'fileUpload') {
        formData.append(key, data[key][0])
      } else {
        formData.append(key, data[key])
      }
    }
    return formData
  }

  const handleSubmit = e => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': 'contact',
        ...formFields,
      }),
    })
      .then(response => {
        setFeedbackMessage("Thanks for your message, we'll be in touch!")
        formRef.current.reset()
      })
      .catch(err => setFeedbackMessage('Error, please try again.'))
  }

  useEffect(() => {
    if (contactIsOpen) {
      setFormFields({ name: '', email: '', message: '' })
      setFeedbackMessage(null)

      if (window.innerWidth <= 1024) {
        if (
          !document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.add('disable-scroll')
        }
      } else {
        if (window?.scroll?.stop) {
          window.scroll.stop()
        }
      }
    } else {
      if (window.innerWidth <= 1024) {
        if (
          document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.remove('disable-scroll')
        }
      } else {
        if (window?.scroll?.update) {
          window.scroll.update()
          window.scroll.start()
        }
      }
    }
  }, [contactIsOpen])

  useEffect(() => {
    const checkKey = e => {
      if (contactIsOpen) {
        if (e.key === 'Escape' || e.code === 'Escape') {
          dispatch({ type: 'CIRCLE_SHRINK' })
          dispatch({ type: 'HIDE_CONTACT' })
          dispatch({ type: 'HIDE_MODAL' })
        }
      }
    }

    window.addEventListener('keyup', checkKey)

    return () => {
      window.removeEventListener('keyup', checkKey)
    }
  }, [contactIsOpen, dispatch])

  return (
    <Contact
      ref={contactRef}
      modalOpen={contactIsOpen}
      onClick={() => {
        if (contactIsOpen) {
          dispatch({ type: 'CIRCLE_SHRINK' })
          dispatch({ type: 'HIDE_CONTACT' })
          dispatch({ type: 'HIDE_MODAL' })
        } else {
          dispatch({ type: 'CIRCLE_GROW' })
          dispatch({ type: 'SHOW_CONTACT' })
          dispatch({ type: 'HALF_SHOW_MODAL' })
        }
      }}
      tabIndex={contactIsOpen ? -1 : 0}
      aria-live="polite"
      role="dialog"
      aria-label="A form to contact us"
    >
      <Container>
        <Grid>
          <GridItem mobile={16} tabletL={10} tabletLStart={4}>
            {' '}
            {/* <FocusTrap active={contactIsOpen}> */}
            <ContactInner
              modalOpen={contactIsOpen}
              onClick={e => e.stopPropagation()}
            >
              <CloseButton>
                <Close
                  onClick={() => {
                    if (contactIsOpen) {
                      dispatch({ type: 'CIRCLE_SHRINK' })
                      dispatch({ type: 'HIDE_CONTACT' })
                      dispatch({ type: 'HIDE_MODAL' })
                    } else {
                      dispatch({ type: 'CIRCLE_GROW' })
                      dispatch({ type: 'SHOW_CONTACT' })
                      dispatch({ type: 'HALF_SHOW_MODAL' })
                    }
                  }}
                >
                  Close
                </Close>
              </CloseButton>
              <Content feedbackMessage={feedbackMessage}>
                <Heading2>Get in touch</Heading2>

                <ContactForm
                  encType="multipart/form-data"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  method="post"
                  name={`contact`}
                  onSubmit={event => handleSubmit(event)}
                  ref={formRef}
                >
                  {/**mandatory netlify form fields */}
                  <input type="hidden" name="bot-field" />
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />

                  <ContactInput
                    type={'text'}
                    ref={nameRef}
                    name={`name`}
                    id={`name`}
                    placeholder={`Name *`}
                    required
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        name: e.target.value,
                      })
                    }}
                  ></ContactInput>
                  <ContactLabel htmlFor={`name`}>Name</ContactLabel>
                  <ContactInput
                    type={'email'}
                    ref={emailRef}
                    name={`email`}
                    id={`email`}
                    placeholder={`Email *`}
                    required
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        email: e.target.value,
                      })
                    }}
                  ></ContactInput>

                  <ContactLabel htmlFor={`email`}>Email</ContactLabel>

                  <ContactTextArea
                    rows={10}
                    ref={messageRef}
                    name={`message`}
                    id={`message`}
                    placeholder={`Message (optional)`}
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        message: e.target.value,
                      })
                    }}
                  ></ContactTextArea>

                  <ContactLabel htmlFor={`message`}>Message</ContactLabel>
                  <Button
                    text={`Submit`}
                    type={`submit`}
                    hasborder
                    theme={3}
                  ></Button>
                </ContactForm>
              </Content>{' '}
              <FeedbackMessage feedbackMessage={feedbackMessage}>
                {feedbackMessage && <Heading2>{feedbackMessage}</Heading2>}
              </FeedbackMessage>
            </ContactInner>
            {/* </FocusTrap> */}
          </GridItem>
        </Grid>
      </Container>
    </Contact>
  )
}

export default ContactModal
